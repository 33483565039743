<template>
  <div :class="{ contain: true, mobile: isMobile }" v-if="!oauth_token">
    <div class="d-flex flex-column justify-center align-center banner" style="width: 100%">
      <!-- <img
        v-if="uploadFileUrl"
        class="clo"
        width="31px"
        height="31px"
        @click.stop="closeDialog"
        src="@/assets/btn_close.png"
        alt=""
      /> -->
      <FIleInput accept=".jpg,.jpeg,.png,.gif" @change="change" :key="fileInputKey">
        <template>
          <v-btn dark width="161px" height="40px" rounded class="bannerBtn">{{ $t("myInfoEditProfileBanner") }}
          </v-btn>
        </template>
      </FIleInput>
      <v-img class="bannerurl" v-if="uploadFileUrl" :src="localFileUrl || uploadFileUrl" width="100%" height="100%" cover></v-img>
    </div>

    <div class="image">
      <v-avatar class="ava" size="191">
        <v-img v-if="typeof uploadAvatar == 'undefined'" src="@/assets/default.png" alt="" width="100%" height="100%" />
        <v-img v-else :src="localAvatar || uploadAvatar" alt="" width="100%" height="100%" />
      </v-avatar>
      <div class="edit_img mt-lg-4 mt-8 mb-lg-8 mb-6">
        <FIleInput accept=".jpg,.jpeg,.png,.gif" @change="avaChange" :key="fileInputKey">
          <div class="edit_image">{{ $t("myInfoEditImage") }}</div>
        </FIleInput>
      </div>
    </div>
    <div class="form">
      <div class="copy_link ml-lg-12 mb-10 d-flex flex-row">
        <span v-if="!isMobile">{{ did }}</span>
        <span v-if="isMobile">{{ did | didfilter }}</span>
        <img
          class="copy"
          :class="{ click: isCopy }"
          src="@/assets/icons/icon_copy.png"
          @click="onCopyClick"
          alt=""
        />
      </div>
      <div>
        <div class="title mb-2">
          {{ $t("myInfoName") }}<span class="star">*</span>
        </div>
        <div class="box">
          <v-text-field ref="nameValue" v-model="nameValue" :rules="nameRules" label="" outlined maxlength="25">
          </v-text-field>
        </div>
      </div>
       <div>
        <div class="title ">
          Social Connections
        </div>
        <div class="des mb-5">
          Help collectors verify your account by connecting Twitter
        </div>
      </div>
      <div class="d-flex justify-space-between mb-9" >
        <div class="twtter d-flex flex-row">
          <img class="img"  src="@/assets/icons/icon_twitter1.png" alt="" />
          <div class="twtterDes">Your Twitter</div>
        </div>
        <div class="connect" :class="{twitterConnect:this.twitterVerify}">
          <div v-if="!this.twitterVerify" style="width:100%;height:100%">
               <button  class="connectText" @click="Connect" >Connect</button>
          </div>
          <div v-else style="width:100%;height:100%">
              <button  class="connectTwitter"   ><span>{{twitterName}}</span> </button>
          <img
          v-if="this.twitterVerify"
          class="clo"
          @click="disConnect"
          src="@/assets/icons/btn_close.png"
          alt=""
        />
        </div>
      </div>
       
      </div>
      <div class="box">
        <div class="title mt-3 mb-2">
          {{ $t("popupAccountAppealDescription") }}
        </div>
        <div>
          <v-textarea outlined ref="descValue" name="input-7-4" label="" counter="800" maxlength="800"
            :placeholder="this.$t('myInfoFormDescriptionPlaceholder')" v-model="descValue" >
          </v-textarea>
          <!-- <v-textarea outlined ref="descValue" name="input-7-4" label="" counter="800" maxlength="800"
            :placeholder="this.$t('myInfoFormDescriptionPlaceholder')" v-model="descValue" :rules="descRules">
          </v-textarea> -->
        </div>
      </div>
      <div class="footer box">
        <button class="sub" submit @click="subCommit">
          {{ $t("myInfoFormSave") }}
        </button>
      </div>
      <v-snackbar v-model="isShowSuccess" color="success" centered timeout="2000" style="opacity: 0.8"><span
          style="color: white; font-size: 20px">{{
              successTitle
          }}</span></v-snackbar>
      <v-snackbar v-model="isShowFalse" color="error" centered timeout="2000" style="opacity: 0.8"><span
          style="color: white; font-size: 20px">{{
              falseTitle
          }}</span></v-snackbar>
    </div>

    <PromptBox ref="promptBox"></PromptBox>

    <uComponents ref="ucom"></uComponents>
  </div>
</template>

<script>
import FIleInput from "@/components/FIleInput.vue";
import api from "@/api";
import { getFileSrc } from "@/utils/file";
import PromptBox from "@/components/PromptBox.vue";
import logoimg from "@/assets/default.png";
let hello = require('hellojs/dist/hello.all.js');

const UPLOAD_KEY = "UPLOAD";
const SYNOPSIS_KEY = "SYNOPSIS";

export default {
  components: { FIleInput, PromptBox },
  data: function () {
    return {
      isCopy: false,
      did: "",
      nameValue: "",
      descValue: "",
      nameRules: [(v) => !!v || "Username is required."],
      // descRules: [(v) => !!v || "Description is required."],
      uploadFileUrl: undefined,
      uploadAvatar: undefined,
      localAvatar: "",
      localFileUrl: "",
      homeBackground: "",
      profilePhoto: "",
      isShowSuccess: false,
      isShowFalse: false,
      successTitle: "",
      falseTitle: "",
      uploadInfo: "",
      logoimg,

      uploadfile: [],
      imgUrl: [],
      imgInputKey: 0,

      fileInputKey: 0,
      sendcode_time: 0,
      oauth_token:'',
        twitterName:'',
        twitterVerify:false,
    };
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
    showAlert: function () {
      return !!this.uploadInfo;
    },
  },
  async mounted() {
     this.oauth_token =  this.$route.query.oauth_token
      this.twws();
    if (this.$store.state.did != null) {
      let param = {
        address: this.$store.state.did,
      };
      let res = await api.home.loopringUserInfo(param);

      if (res.code == 10001 || res.data == null) {
        this.did = this.$store.state.did;
        this.nameValue = "";
        this.descValue = "";
        this.homeBackground = "";
        this.profilePhoto = "";

        //this.uploadFileUrl = logoimg
        this.uploadAvatar = logoimg;
      } else {
           this.twitterVerify = res.data.twitterVerify;
        this.twitterName = res.data.twitterName;
        this.did = res.data.address;
        this.accountStatus = res.data.accountStatus;
        this.nameValue = res.data.name == "null" ? "" : res.data.name;
        this.descValue = res.data.description == "null" ? "" : res.data.description;
        this.homeBackground =
          res.data.background == "null" ? "" : res.data.background;
        this.profilePhoto =
          res.data.profilePhoto == "null" ? "" : res.data.profilePhoto;

        // this.$store.commit("getInfo", res.data);

        if (this.homeBackground) {
          this.uploadFileUrl = await getFileSrc(
            UPLOAD_KEY,
            this.homeBackground
          );
        } else {
          this.uploadFileUrl = logoimg;
        }
        if (this.profilePhoto) {
          this.uploadAvatar = await getFileSrc(UPLOAD_KEY, this.profilePhoto);
        } else {
          this.uploadAvatar = logoimg;
        }
        if (res.data.synopsisImage) {
          let imgLists = (res.data.synopsisImage).split(",");
          imgLists.forEach(async (item) => {
            if (item != '') {
              let dessrc = await getFileSrc(SYNOPSIS_KEY, item)
              this.uploadfile.push(dessrc)
              this.imgUrl.push(item)
            }
          })

        }
      }
    }
  },
  filters: {
    didfilter: function (value) {
      if (value && value.length > 24) {
        return value.substr(0, 12) + "..." + value.substr(-12);
      } else {
        return "";
      }
    },
  },
  methods: {
     twws(){
        hello.init({
          twitter : '3ZvteQzzRtwyAYuUOirbIjVaC',
        },{
          scope : 'email',
          redirect_uri: 'https://loopring.upticknft.com/myInfo'
        });
        
      },
     async Connect(){
          // twitter
 
     hello('twitter').login({force: true }).then((res)=> {   
       let user_name;
       user_name =res.authResponse.screen_name;
        this.twitterName =user_name
        this.twitterVerify = true
       console.log( '登录成功');
       console.log("wxl --sfew",user_name,this.twitterName)
		},
		function(err) {
			console.log(err, '登录失败');
    }
     
  );
  
  // 	hello.on('auth.login', result => {
	// 	hello(result.network).api('me').then(function(p) {
	// 		console.log(result, 'result'); //输出用户信息
	// 	});
	// }); 
      },
      disConnect(){
        this.twitterName = ''
        this.twitterVerify = false
      },
    async subCommit() {
      if (!(await this.verify())) {
        return;
      }
      // this.descValue = this.descValue == "null" ? "" : this.descValue;
      this.homeBackground =
        this.homeBackground == "null" ? "" : this.homeBackground;
      this.profilePhoto = this.profilePhoto == "null" ? "" : this.profilePhoto;
      this.imgUrl = this.imgUrl == "" ? "[do-null]" : this.imgUrl;
      let nameValue = this.nameValue;
      let descValue = this.descValue;
      if (this.nameValue) {
        nameValue = encodeURIComponent((this.nameValue).replace(/(^\s*)|(\s*$)/g, ""));
      }
      if (this.descValue) {
        descValue = encodeURIComponent((this.descValue).replace(/(^\s*)|(\s*$)/g, ""));
      }

      if(window.walletType == 3) {
        this.accountStatus = 3;
      }
      let params = {
        address: this.$store.state.did,
        name: nameValue,
        description: descValue,
        background: this.homeBackground,
        profilePhoto: this.profilePhoto,
        twitterName:this.twitterName ?this.twitterName : '',
        twitter:'https://twitter.com/' + (this.twitterName ?this.twitterName : ''),
      };
      // params.homepage = params.homepage == null ? "[do-null]" : params.homepage;
      let res = await api.home.loopringUserSave(params);
      window.eventBus.$emit("MidifyUser");
      this.$toast("success", res.msg).then(() => {
        this.$router.push({name:"PersonalPage"});
      });
      // this.$refs.promptBox.show(res.msg);
      // if(res.msg === 'Succeed'){
      //   this.isShowSuccess = true
      //   this.successTitle= res.msg
      // }else{
      //     this.isShowFalse =true
      //     this.falseTitle= res.msg
      // }
    },
    async verify() {
      let nameVer = this.$refs.nameValue.validate(true);
      let descValue = this.$refs.descValue.validate(true);
      !nameVer ? this.$refs.nameValue.focus() : "";
      !descValue ? this.$refs.descValue.focus() : "";
      return nameVer && descValue;
    },
    async change(file) {
      // JPG,PNG,GIF
      let ft = file.name.substr(file.name.lastIndexOf(".") + 1);
      if ("JPG,JPEG,PNG,GIF,MP4".indexOf(ft.toUpperCase()) == -1) {
        this.$toast("error", this.$t("file_type_not_support"));
        this.fileInputKey++;
        return;
      }

      this.fileInputKey++;
      // this.banHash = await api.image.uploadImage(file);
      let { path } = await this.ipfsAddFile(file);
      if (path) {
        this.homeBackground = path;
        this.uploadFileUrl = this.getImageIpfs(path);
      }

      this.localFileUrl = window.URL.createObjectURL(file);
    },
    async avaChange(file) {
      // JPG,PNG,GIF
      let ft = file.name.substr(file.name.lastIndexOf(".") + 1);
      if ("JPG,JPEG,PNG,GIF,MP4".indexOf(ft.toUpperCase()) == -1) {
        this.$toast("error", this.$t("file_type_not_support"));
        this.fileInputKey++;
        return;
      }

      this.fileInputKey++;
      // this.avaHash = await api.image.uploadImage(file);
      let { path } = await this.ipfsAddFile(file);
      if (path) {
        this.profilePhoto = path;
        this.uploadAvatar = this.getImageIpfs(path);
      }
      
      this.localAvatar = window.URL.createObjectURL(file);
    },
    closeDialog() {
      this.uploadFileUrl = "";
      this.homeBackground = "";
    },
    onCopyClick() {
      var input = document.createElement("input");
      input.value = this.did;
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
      this.isCopy = true;
      setTimeout(() => (this.isCopy = false), 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.contain {
  margin: 0;
  padding: 0;

  .banner {
    height: 301px;
    background-color: #f8f6fd;
    position: relative;

    .clo {
      visibility: hidden;
      position: relative;
      left: 580px;
      top: 15px;
      background-color: #fff;
      border-radius: 50%;
      cursor: pointer;
      z-index: 9999;
    }

    &:hover .clo {
      visibility: visible;
    }

    .bannerBtn {
      z-index: 10;
      position: absolute;
      left: 43%;
      top: 40%;
    }

    .bannerurl {
      margin-top: -60px;
    }

    .edit_profile {
      margin: 0 auto;
      text-align: center;
      width: 186px;
      height: 40px;
      background-color: #270645;
      border-radius: 20px;
      font-weight: bold;
      ;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 40px;
      letter-spacing: 0px;
      color: #ffffff;
    }
  }

  .image {
    margin-top: -43px;

    .ava {
      margin-left: 42%;
      background-color: #ddd;
      border: solid 8px #ffffff;
    }

    .edit_img {
      .edit_image {
        cursor: pointer;
        text-align: center;
        margin: 0 auto;
        width: 126px;
        height: 40px;
        background-color: #270645;
        border-radius: 20px;
        font-weight: bold;
        ;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 40px;
        letter-spacing: 0px;
        color: #ffffff;
        -webkit-user-select: none;
      }
    }
  }

  .form {
    height: auto;
    margin: 0 384px;

    .copy_link {
      text-align: center;
     .click {
      margin-top: 5px;
    }
      span {
        display: inline-block;
        font-family: Helvetica;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #270645;
      }

      .copy {
        display: inline-block;
        width: 13px;
        height: 15px;
        margin-left: 8px;
        cursor: pointer;
      }
    }

    .title {
      font-weight: bold;
      ;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
    }
     .twtter{
      margin: auto 0px;
      img{
        width: 25px;
        height: 25px;
      }
      .twtterDes{
        margin-left: 13px;
        font-family: Helvetica;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      }
    }
    .twitterConnect{
      	border: solid 2px #e3e3e3 !important;
        background-color: #ffffff !important;
    }
     .connect{
      min-width: 100px;
      height: 41px;
      background-color: #270645;
      border-radius: 20px;
              position: relative;
    
      .connectText{
        width: 100%;
        height: 100%;
         font-family: MicrosoftYaHei;
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #ffffff;
        text-align: center;
        align-items: center;
  
      }
      .connectTwitter{
        width: 85%;
        height: 100%;
         font-family: MicrosoftYaHei;
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #ffffff;
        text-align: center;
        align-items: center;
        margin-left: 20px;
        margin-right: 20px;  
        span{
          	color: #1d42ff;
        } 

      }
      .clo {
    position: absolute;
    top: 11px;
    left: 5px;
    width: 15px;
    height: 15px;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
    border-radius: 50% !important;
    z-index: 99;
  }
    

    }
    .des{
      font-family: Helvetica;
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 0px;
	color: #766983;
    }

    .box {
      width: 450px;
    }

    .inputimg {
      width: 450px;

      .uploadfile {
        margin-bottom: 14px;
        width: 141px;
        height: 140px;
        background-image: linear-gradient(#ffffff, #ffffff),
          linear-gradient(#f8f6fd, #f8f6fd);
        background-blend-mode: normal, normal;
        border-radius: 1px;
        border: solid 2px #e3e3e3;

        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-right: 15px;
        object-fit: contain;
        cursor: pointer;

        .FIleInput {
          width: 100%;
          height: 100%;
          padding: 30px 23px;

          .v-icon {
            width: 100%;
            height: 100%;
            padding: 25px;
          }

          .imgbtn {
            margin: 5px auto;
            background-color: #270645;
            border-radius: 12px;
            font-weight: bold;
            ;
            font-size: 9px;
            font-weight: bold;
            font-stretch: normal;
            line-height: 24px;
            letter-spacing: 0px;
            color: #ffffff;
          }
        }

        .icon {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          position: absolute;
          top: 0px;
          right: 0px;
          background-color: #fff;
          z-index: 99;
          cursor: pointer;
        }

        .img {
          width: 100%;
          height: 100%;
          margin: 0;
          object-fit: contain;
        }
      }

      .file-limit {
        // width: 137px;
        height: 13px;
        font-family: Helvetica;
        font-size: 10px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 14px;
        letter-spacing: 0px;
        color: #766983;
        margin-top: 5px;
        text-align: center;
      }
    }
  }

  .footer {
    margin-bottom: 190px;

    .sub {
      margin: 0 auto;
      width: 450px;
      height: 51px;
      background-color: #1d42ff;
      background-blend-mode: normal, normal;
      border-radius: 25px;
      opacity: 0.9;
      font-size: 20px;
      color: #ffffff;
      font-weight: bold;
    }
  }

  &.mobile {

    // width:100%;
    .banner {
      width: 100%;
      height: 180px;
      position: relative;

      .clo {
        visibility: hidden;
        position: relative;
        left: 90px;
        top: 15px;
        background-color: #fff;
        border-radius: 50%;
        cursor: pointer;
        z-index: 9999;
      }

      &:hover .clo {
        visibility: visible;
      }

      // padding-top: 30px;
      .bannerBtn {
        position: absolute;
        left: calc(50% - 80px);
        top: 30%;
      }

      .bannerurl {
        margin-top: -60px;
      }

      .edit_profile {
        margin: 0 auto;
      }
    }

    .image {
      margin-top: -50px;
      margin-left: 45px;

      .ava {
        margin: 0;
      }

      .edit_img {

        // width: 100%;
        .edit_image {
          margin-left: 30px;
        }
      }
    }

    .form {
      // width: 100%;
      margin: 0;

      .copy_link {
        width: 100%;
        
        span {
          width: 100%;
          display: inline-block;
        }

        .copy {
          display: inline-block;
        }
      }

      .box {
        width: 100%;
      }

      .inputimg {
        width: 100%;

        .uploadfile {
          width: 280px;
          height: 280px;

          .FIleInput {
            text-align: center;
            margin-top: 55%;
          }
        }

      }
    }

    .footer {
      .sub {
        width: 100%;
      }
    }
  }
}

.InstagramValue ::v-deep .v-icon::before {
  content: "";
  background-image: url(../../assets/icons/icon_Instagram.png);
  background-size: 100%;
  width: 22px;
  height: 22px;
}

.TwitterValue ::v-deep .v-icon::before {
  content: "";
  background-image: url(../../assets/icons/icon_twitter.png);
  background-size: 100%;
  width: 22px;
  height: 22px;
}

.YoutubeValue ::v-deep .v-icon::before {
  content: "";
  background-image: url(../../assets/icons/icon_youtube.png);
  background-size: 100%;
  width: 22px;
  height: 22px;
}

.SiteValue ::v-deep .v-icon::before {
  content: "";
  background-image: url(../../assets/icons/icon_site.png);
  background-size: 100%;
  width: 22px;
  height: 22px;
}

.alert {
  position: fixed;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
}

.star {
  color: red;
  margin-left: 10px;
}

.contain ::v-deep.v-messages__message {
  color: red;
}

.verifycode {
  cursor: pointer;
  text-align: center;
  margin: 0 auto;
  width: 126px;
  height: 40px;
  background-color: #270645;
  border-radius: 20px;
  font-weight: bold;
  ;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 40px;
  letter-spacing: 0px;
  color: #ffffff;
  -webkit-user-select: none;
  position: absolute;
  right: 8px;
  top: 8px;
}

.verifycode_dis {
  background-color: #766983 !important;
}

.code ::v-deep .v-text-field__details {
  display: none;
}


.mobile {
    .image {
        margin-left: 0px !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        .edit_image {
          margin-left: 0px !important;
        }
        .v-text-field {
          margin-top: 0px;
          padding-top: 0px;
        }
    }
}
</style>
